<template>
    <div class="flex flex-wrap">
        <div class="w-full">
            <CardAbout />
        </div>
    </div>
</template>
<script>
import CardAbout from "@/components/Cards/cardsinput/about/CardAbout.vue";

export default {
    components: {
        CardAbout,
    },
};
</script>
