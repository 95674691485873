<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="rounded-t mb-0 px-6 py-6">
            <div class="text-center mb-3">
              <img
                src="../../assets/img/logo-header-txt.png"
                alt="Hexagon Inc"
              />
            </div>
            <hr class="mt-4 border-b-1 border-blueGray-300" />
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form @submit.prevent="handleLogin">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="email"
                >
                  Email
                </label>
                <input
                  type="email"
                  v-model="formData.email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Email"
                  required
                />
              </div>
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="password"
                >
                  Password
                </label>
                <div class="relative">
                  <input
                    :type="showPassword ? 'text' : 'password'"
                    v-model="formData.password"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 pr-10"
                    placeholder="Password"
                    required
                  />
                  <button
                    type="button"
                    @click="togglePasswordVisibility"
                    class="absolute right-0 top-0 mt-4 mr-3 flex items-center text-sm leading-5"
                  >
                    <i v-if="showPassword" class="fas fa-eye-slash"></i>
                    <i v-else class="fas fa-eye"></i>
                  </button>
                </div>
              </div>
              <div class="text-center mt-6">
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit"
                >
                  Masuk
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import initAPI from "@/axios";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

export default {
  setup() {
    const showPassword = ref(false);
    const router = useRouter();
    const formData = ref({
      email: "",
      password: "",
      rememberMe: false,
    });

    // untuk login
    const handleLogin = async () => {
      try {
        const data = {
          Email: formData.value.email,
          Password: formData.value.password,
        };
        const response = await initAPI("post","Login",JSON.stringify(data),null);
        console.log("Response data:", response.data);
        const token = response.data.token;
        Cookies.set("token", token, { expires: 1 });
        Swal.fire({
          icon: "success",
          title: "Login berhasil",
          showConfirmButton: false,
          timer: 1500,
        });
        router.push("/admin/dashboard");
      } catch (error) {
        console.error("Error during login:", error);
        if (error.response && error.response.status === 404) {
          Swal.fire({
            icon: "error",
            title: "Login gagal",
            text: "Endpoint login tidak ditemukan.",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Login gagal",
            text: "Silakan periksa kredensial Anda dan coba lagi.",
          });
        }
      }
    };

    //untuk menampilkan password
    const togglePasswordVisibility = () => {
      showPassword.value = !showPassword.value;
    };

    return { formData, showPassword, handleLogin, togglePasswordVisibility };
  },
};
</script>
