<template>
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div class="rounded-t bg-white mb-0 px-6 py-6">
            <div class="text-center flex justify-between">
                <h6 class="text-blueGray-700 text-xl font-bold uppercase">Category News</h6>
                <router-link to="/admin/category_news">
                    <button
                        class="bg-indigo-500 text-white active:bg-lightBlue-300 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button">
                        Balik ke tables
                    </button>
                </router-link>
            </div>
        </div>
        <form @submit.prevent="kirimData">
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full my-4">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            Nama Category
                        </label>
                        <input type="text" v-model="kategori"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Masukkan nama category" />
                    </div>
                </div>
                <div class="px-4">
                    <button type="submit" :class="{ 'opacity-50 cursor-not-allowed': isLoading }" :disabled="isLoading"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-100 bg-indigo-500 rounded text-sm shadow w-full">
                        <span v-if="isLoading">Loading...</span>
                        <span v-else>KIRIM</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { ref } from 'vue';
import Swal from 'sweetalert2';
import initAPI from '@/axios.js';
import Cookies from 'js-cookie';

export default {
    setup() {
        const token = Cookies.get('token');
        const kategori = ref('');
        const isLoading = ref(false);

        const kirimData = async () => {
            if (!kategori.value.trim()) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Kolom kosong',
                    text: 'Semua kolom harus diisi.'
                });
                return;
            }
            try {
                isLoading.value = true;
                const dataToSend = new FormData();
                dataToSend.append('nama_category', kategori.value);
                await initAPI('post', 'category_news/Store', dataToSend, token);
                Swal.fire({
                    icon: 'success',
                    title: 'Berhasil',
                    text: 'Data berhasil dikirim.',
                });
                kategori.value = '';
            } catch (error) {
                console.error("Error:", error);
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Gagal mengirim data ke server.',
                    timer: 3000,
                    showConfirmButton: false
                });
            } finally {
                isLoading.value = false;
            }
        };

        return { kategori, kirimData, isLoading };
    }
};
</script>
