<template>
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div class="rounded-t bg-white mb-0 px-6 py-6">
            <div class="text-center flex justify-between">
                <h6 class="text-blueGray-700 text-xl font-bold uppercase">Client</h6>
                <router-link to="/admin/client">
                    <button
                        class="bg-indigo-500 text-white active:bg-lightBlue-300 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button">
                        Balik ke tables
                    </button>
                </router-link>
            </div>
        </div>
        <form @submit.prevent="kirimData">
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                <img :src="tmpfile" v-if="tmpfile" class="rounded-lg w-[1160px] h-[760px] my-3 border-2 border-black">
                <img src="https://fakeimg.pl/800x400/" v-else alt="Gambar Cadangan"
                    class="rounded-lg w-full h-[760px] my-3 border-2 border-black">
                <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            Upload Foto
                        </label>
                        <input type="file" @change="tampilFoto" accept="image/*"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                    </div>
                </div>
                <div class="px-4">
                    <button type="submit" :class="{ 'opacity-50 cursor-not-allowed': isLoading }" :disabled="isLoading"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-100 bg-indigo-500 rounded text-sm shadow w-full">
                        <span v-if="isLoading">Loading...</span>
                        <span v-else>KIRIM</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import initApi from '@/axios.js';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
export default {
    setup() {
        const token = Cookies.get('token');
        const tmpfile = ref(null);
        const file = ref(null);
        const isLoading = ref(false);

        const tampilFoto = (event) => {
            const fileInput = event.target.files[0];
            file.value = fileInput;
            tmpfile.value = URL.createObjectURL(fileInput);
        };

        const postPorto = async () => {
            if (!file.value) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Kolom kosong',
                    text: 'Semua kolom harus diisi.'
                });
                return;
            }
            try {
                isLoading.value = true;
                const dataToSend = new FormData();
                dataToSend.append('foto_client', file.value);
                const response = await initApi('post', `abt_client/Store`, dataToSend, token);
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Berhasil mengirim data ke server',
                });
                file.value = null;
                tmpfile.value = null;
            } catch (error) {
                console.error("Error:", error);
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Gagal mengirim data ke server.',
                    timer: 3000,
                    showConfirmButton: false
                });
            } finally {
                isLoading.value = false;
            }
        };

        const kirimData = async () => {
            await postPorto();
        };

        return { tmpfile, file, tampilFoto, kirimData, isLoading };
    }
};
</script>
