<template>
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div class="rounded-t bg-white mb-0 px-6 py-6">
            <div class="text-center flex justify-between">
                <h6 class="text-blueGray-700 text-xl font-bold uppercase">Review</h6>
                <router-link to="/admin/review">
                    <button
                        class="bg-indigo-500 text-white active:bg-lightBlue-300 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button">
                        Balik ke tables
                    </button>
                </router-link>
            </div>
        </div>
        <form @submit.prevent="kirimData">
            <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                    <div class="flex flex-wrap">
                        <img :src="tmpfile" v-if="tmpfile" class="rounded-lg w-48 h-48 m-2 border-2 border-black">
                    </div>
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Upload Foto
                    </label>
                    <input type="file" @change="tampilFoto" accept="image/*" class=" border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm
                        shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                </div>
            </div>
            <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full my-4">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            Pilih kategori
                        </label>
                        <select
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            v-model="kategori">
                            <option v-for="kategoriS in datakategori" :key="kategoriS.id" :value="kategoriS.id"
                                class="text-black">{{ kategoriS.nama_category }}</option>
                        </select>
                    </div>
                </div>
            <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full my-4">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="keterangan">
                        Nama
                    </label>
                    <input type="text" v-model="nama"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                </div>
            </div>
            <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Review
                    </label>
                    <textarea id="keterangan" v-model="review"
                        class="border-0 px-3 py-3 placeholder-blueGray-300  text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        rows="4" placeholder="Masukan review disini"></textarea>
                </div>
            </div>
            <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full my-4">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="keterangan">
                        Dari
                    </label>
                    <input type="text" v-model="dari"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                </div>
            </div>
            <div class="px-4">
                <button type="submit" :class="{ 'opacity-50 cursor-not-allowed': isLoading }" :disabled="isLoading"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-100 bg-indigo-500 rounded text-sm shadow w-full">
                    <span v-if="isLoading">Loading...</span>
                    <span v-else>KIRIM</span>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
/* eslint-disable */
import { ref,onMounted } from 'vue';
import initAPI from '@/axios.js';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

export default {
    setup() {
        const token = Cookies.get('token');
        const tmpfile = ref(null);
        const file = ref(null);
        const dari = ref('');
        const nama = ref('');
        const review = ref('');
        const kategori = ref(null);
        const datakategori = ref([]);
        const isLoading = ref(false);

        const tampilFoto = (event) => {
            const fileInput = event.target.files[0];
            file.value = fileInput;
            tmpfile.value = URL.createObjectURL(fileInput);
        };
        const getReviewCt = async () => {
            try {
                const response = await initAPI('get', 'Category_review', null, null);
                datakategori.value = response.data.data;
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        onMounted(getReviewCt);

        const postReview = async () => {
            if (!review.value.trim() || !dari.value.trim() || !kategori.value || !file.value) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Kolom kosong',
                    text: 'Semua kolom harus diisi.'
                });
                return;
            }
            try {
                isLoading.value = true;
                const dataToSend = new FormData();
                dataToSend.append('Tag_iD', kategori.value);
                dataToSend.append('foto', file.value);
                dataToSend.append('review', review.value);
                dataToSend.append('nama', nama.value);
                dataToSend.append('dari', dari.value);
                const response = await initAPI('post', 'review/Store', dataToSend, token);
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Berhasil mengirim data ke server',
                });
                dari.value = '';
                nama.value = '';
                review.value = '';
                file.value = null;
                tmpfile.value = null;
                kategori.value = null;
            } catch (error) {
                console.error("Error:", error);
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Gagal mengirim data ke server.',
                    timer: 3000,
                    showConfirmButton: false
                });
            } finally {
                isLoading.value = false;
            }
        };

        const kirimData = async () => {
            await postReview();
        };

        return { tmpfile, file, dari, nama,  kategori, datakategori, review, tampilFoto, kirimData, isLoading };
    }
};
</script>
