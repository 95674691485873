import axios from 'axios';
import { useRouter } from 'vue-router';
import Cookies from 'js-cookie';

const initAPI = async (method, endpoint, data, token) => {
    const router = useRouter();
    const baseUrl = process.env.VUE_APP_API_BASE_URL;
    let check = endpoint.includes('News') || endpoint.includes('Service') || endpoint.includes('Portofolio') || endpoint.includes('abt_client') || endpoint.includes('abt_value') || endpoint.includes('abt_team') || endpoint.includes('review') || endpoint.includes('fotoprojek');
    const config = {
        method: method,
        maxBodyLength: Infinity,
        url: baseUrl + endpoint,
        headers: {
            'Content-Type': check ? 'multipart/form-data' : 'application/json',
            'Authorization': token !== null ? `Bearer ${token}` : null
        },
        data: data !== null ? data : null
    };

    try {
        const response = await axios.request(config);
        return response;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            Cookies.remove('token'); // remove the token
            router.push('/auth/login'); // redirect to login page
        } else {
            throw error;
        }
    }
}

export default initAPI;
