<template>
    <div class="flex flex-wrap">
        <div class="w-full px-4">
            <CardPortofolio />
        </div>
    </div>
</template>
<script>
import CardPortofolio from "@/components/Cards/cardsinput/portofolio/CardPortofolio.vue";

export default {
    components: {
        CardPortofolio,
    },
};
</script>