<template>
    <div class="flex flex-wrap">
        <div class="w-full px-4">
            <CardCareer />
        </div>
    </div>
</template>
<script>
import CardCareer from "@/components/Cards/cardsinput/career/CardCareer.vue";

export default {
    components: {
        CardCareer
    },
};
</script>