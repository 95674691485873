<template>
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div class="rounded-t bg-white mb-0 px-6 py-6">
            <div class="text-center flex justify-between">
                <h6 class="text-blueGray-700 text-xl font-bold uppercase">Portofolio</h6>
                <router-link to="/admin/portofolio">
                    <button
                        class="bg-indigo-500 text-white active:bg-lightBlue-300 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button">
                        Balik ke tables
                    </button>
                </router-link>
            </div>
        </div>
        <form @submit.prevent="kirimData">
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full my-4">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            Pilih kategori
                        </label>
                        <select
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            v-model="kategori">
                            <option v-for="kategoriS in datakategori" :key="kategoriS.id" :value="kategoriS.id"
                                class="text-black">{{ kategoriS.nama_category }}</option>
                        </select>
                    </div>
                </div>
                <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full mb-3">
                        <div class="flex flex-wrap">
                            <img v-for="(file, index) in tmpfiles" :key="index" :src="file"
                                class="rounded-lg w-48 h-48 m-2 border-2 border-black">
                        </div>
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            Upload Foto
                        </label>
                        <input type="file" @change="tampilFoto" accept="image/*" multiple
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                    </div>
                </div>
                <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            Judul
                        </label>
                        <input type="text" v-model="judul"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                    </div>
                </div>
                <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full my-4">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="keterangan">
                            Keterangan
                        </label>
                        <textarea id="keterangan" v-model="keterangan"
                            class="border-0 px-3 py-3 placeholder-blueGray-300  text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            rows="4" placeholder="Masukan keterangan disini"></textarea>
                    </div>
                </div>
                <div class="px-4">
                    <button type="submit" :class="{ 'opacity-50 cursor-not-allowed': isLoading }" :disabled="isLoading"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-100 bg-indigo-500 rounded text-sm shadow w-full">
                        <span v-if="isLoading">Loading...</span>
                        <span v-else>KIRIM</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import initAPI from '@/axios.js';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

export default {
    setup() {
        const token = Cookies.get('token');
        const tmpfiles = ref([]);
        const files = ref([]);
        const judul = ref('');
        const keterangan = ref('');
        const kategori = ref(null);
        const datakategori = ref([]);
        const isLoading = ref(false);

        const tampilFoto = (event) => {
            const fileInput = event.target.files;
            tmpfiles.value = [];
            files.value = [];
            for (let i = 0; i < fileInput.length; i++) {
                tmpfiles.value.push(URL.createObjectURL(fileInput[i]));
                files.value.push(fileInput[i]);
            }
        };

        const getPorto = async () => {
            try {
                const response = await initAPI('get', 'Category', null, null);
                datakategori.value = response.data.data;
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        onMounted(getPorto);

        const postPorto = async () => {
            if (!keterangan.value.trim() || !judul.value.trim() || !kategori.value || !files.value.length) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Kolom kosong',
                    text: 'Semua kolom harus diisi.'
                });
                return;
            }
            try {
                isLoading.value = true;
                const dataToSend = new FormData();
                dataToSend.append('Tag_ID', kategori.value);
                dataToSend.append('judul_porto', judul.value);
                dataToSend.append('ket_porto', keterangan.value);
                for (let i = 0; i < files.value.length; i++) {
                    dataToSend.append('images[]', files.value[i]);
                }
                const response = await initAPI('post', 'Portofolio/Store', dataToSend, token);

                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Berhasil mengirim data ke server',
                });

                judul.value = '';
                keterangan.value = '';
                files.value = [];
                tmpfiles.value = [];
                kategori.value = null;
            } catch (error) {
                console.error("Error:", error);
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Gagal mengirim data ke server.',
                    timer: 3000,
                    showConfirmButton: false
                });
            } finally {
                isLoading.value = false;
            }
        };

        const kirimData = async () => {
            await postPorto();
        };

        return { tmpfiles, files, judul, keterangan, kategori, datakategori, tampilFoto, kirimData, isLoading };
    }
};
</script>
