<template>
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <!-- Bagian atas form -->
        <div class="rounded-t bg-white mb-0 px-6 py-6">
            <div class="text-center flex justify-between">
                <h6 class="text-blueGray-700 text-xl font-bold uppercase">Hero</h6>
                <router-link to="/admin/hero">
                    <button
                        class="bg-indigo-500 text-white active:bg-lightBlue-300 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button">
                        Kembali ke tables
                    </button>
                </router-link>
            </div>
        </div>
        <!-- Form -->
        <form @submit.prevent="kirimData">
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                <!-- Embed Youtube -->
                <iframe class="rounded-lg w-full mt-6 border-2 border-black " height="540" :src="linkyt" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <!-- Input link Youtube -->
                <div class="w-full lg:w-12/12 px-4 my-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            Masukkan link YouTube
                        </label>
                        <input type="text" v-model="linkyt"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Link YouTube harus memiliki 'embed' contoh: https://www.youtube.com/embed/(ambil dari link YouTube belakang)" />
                    </div>
                </div>
                <!-- Tombol Kirim -->
                <div class="px-4">
                    <button type="submit" :class="{ 'opacity-50 cursor-not-allowed': loading }" :disabled="loading"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-100 bg-indigo-500 rounded text-sm shadow w-full">
                        <span v-if="loading">Loading...</span>
                        <span v-else>KIRIM</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
/*eslint-disable*/
import { ref } from 'vue';
import initAPI from '@/axios.js';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
export default {
    setup() {
        const token = Cookies.get('token');
        const linkyt = ref('https://www.youtube.com/embed/');
        const loading = ref(false);

        const postDataApi = async () => {
            if (!linkyt.value.trim()) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Kolom kosong',
                    text: 'Semua kolom harus diisi.'
                });
                return;
            }
            try {
                loading.value = true;
                const dataToSend = new FormData();
                dataToSend.append('linkyt', linkyt.value);
                const response = await initAPI('post', 'abt_hero/Store', dataToSend, token);
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Berhasil mengirim data ke server',
                });
                linkyt.value = 'https://www.youtube.com/embed/';
            } catch (error) {
                console.error("Error:", error);
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Gagal mengirim data ke server.',
                    timer: 3000,
                    showConfirmButton: false
                });
            } finally {
                loading.value = false;
            }
        };

        const kirimData = async () => {
            await postDataApi();
        };

        return { linkyt, kirimData, loading };
    }
};
</script>
