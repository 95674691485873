<template>
    <div>
        <div class="relative container px-4 mx-auto bg-white rounded shadow-md">
            <h6 class="text-center text-xl font-semibold uppercase rounded py-3">Manajemen About</h6>
        </div>
        <div class="relative container px-4 mx-auto bg-white rounded mt-5 shadow-md">
            <div class="flex flex-row overflow-x-auto">
                <div class="w-full px-4">
                    <router-link
                        class="text-center bg-white text-sm block my-4 p-3 text-blueGray-700 rounded border border-solid border-blueGray-100 uppercase shadow-lg whitespace-nowrap"
                        to="/admin/brand/"><i class="fas fa-bullhorn mr-2"></i> Brand Message
                    </router-link>
                </div>
                <div class="w-full px-4">
                    <router-link
                        class="text-center bg-white text-sm block my-4 p-3 text-blueGray-700 rounded border border-solid border-blueGray-100 uppercase shadow-lg whitespace-nowrap"
                        to="/admin/slogan/"><i class="fas fa-tag mr-2"></i> Slogan
                    </router-link>
                </div>
                <div class="w-full px-4">
                    <router-link to="/admin/hero/"
                        class="bg-white text-sm text-center block my-4 p-3 text-blueGray-700 rounded border border-solid border-blueGray-100 uppercase shadow-lg whitespace-nowrap">
                        <i class="fas fa-play mr-2"></i> Hero
                    </router-link>
                </div>
                <div class="w-full px-4">
                    <router-link to="/admin/culture/"
                        class="bg-white text-sm text-center block my-4 p-3 text-blueGray-700 rounded border border-solid border-blueGray-100 uppercase shadow-lg whitespace-nowrap">
                        <i class="fas fa-globe mr-2"></i> Culture
                    </router-link>
                </div>
                <div class="w-full px-4">
                    <router-link to="/admin/value"
                        class="bg-white text-sm text-center block my-4 p-3 text-blueGray-700 rounded border border-solid border-blueGray-100 uppercase shadow-lg whitespace-nowrap">
                        <i class="fas fa-heart mr-2"></i> Values
                    </router-link>
                </div>
                <div class="w-full px-4">
                    <router-link to="/admin/team"
                        class="bg-white text-sm text-center block my-4 p-3 text-blueGray-700 rounded border border-solid border-blueGray-100 uppercase shadow-lg whitespace-nowrap">
                        <i class="fas fa-users mr-2"></i> Team
                    </router-link>
                </div>
                <div class="w-full px-4">
                    <router-link to="/admin/philosophy"
                        class="bg-white text-sm text-center block my-4 p-3 text-blueGray-700 rounded border border-solid border-blueGray-100 uppercase shadow-lg whitespace-nowrap">
                        <i class="fas fa-lightbulb mr-2"></i> Philosophy
                    </router-link>
                </div>
                <div class="w-full px-4">
                    <router-link to="/admin/client"
                        class="bg-white text-sm text-center block my-4 p-3 text-blueGray-700 rounded border border-solid border-blueGray-100 uppercase shadow-lg whitespace-nowrap">
                        <i class="fas fa-handshake mr-2"></i> Client
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // script content here
};
</script>