<template>
    <div>
        <div v-if="showModal" class="absolute inset-x-auto w-full p-32 z-50 flex items-center justify-center">
            <div class="relative w-1/2 md:w-full max-w-lg md:max-w-xl lg:max-w-3xl mx-auto my-6">
                <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <div
                        class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                        <h3 class="text-2xl sm:text-3xl font-semibold">Edit Service</h3>
                        <button
                            class="p-1 ml-auto bg-transparent border-0 text-black float-right text-2xl sm:text-3xl leading-none font-semibold outline-none focus:outline-none"
                            @click="closeModal">
                            <span
                                class="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                        </button>
                    </div>
                    <div class="relative p-6 flex-auto">
                        <form @submit.prevent="saveData">
                            <div class="w-full px-4 mt-4">
                                <img v-if="tmpfile || formData.file" :src="tmpfile || getImageUrl(formData.file)"
                                    class="mt-4 rounded-lg w-full h-auto object-cover" />
                                <div class="relative w-full">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Edit
                                        Foto</label>
                                    <input type="file" @change="tampilFoto" accept="image/*"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                                </div>
                            </div>
                            <div class="w-full px-4 mt-4">
                                <div class="relative w-full">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Edit judul
                                        Service</label>
                                    <input type="text" v-model="formData.judul_service"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder="Masukan judul service" />
                                </div>
                            </div>
                            <div class="w-full px-4 my-4">
                                <div class="relative w-full">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold">Edit
                                        keterangan Service</label>
                                    <input type="text" v-model="formData.ket_service"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder="Masukan keterangan service" />
                                </div>
                            </div>
                            <div
                                class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b my-3">
                                <button
                                    class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button" @click="closeModal">Close</button>
                                <button :class="{ 'opacity-50 cursor-not-allowed': loading }" :disabled="loading"
                                    class="bg-indigo-500 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button" @click="saveData">
                                    <span v-if="loading">Loading...</span>
                                    <span v-else>KIRIM</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="relative bg-white flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg">
            <div class="rounded-t mb-0 px-4 py-3 border-0">
                <div class="flex flex-wrap items-center">
                    <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                        <h3 class="font-semibold text-lg text-blueGray-700">Table Service</h3>
                    </div>
                        <router-link to="/admin/service/input" class="flex w-full lg:w-auto">
                            <span
                                class="bg-indigo-500 shadow-md text-sm my-2 mx-4 p-3 text-white rounded w-full text-center md:w-auto md:my-4">Tambah
                                Service</span>
                        </router-link>
                </div>
            </div>

            <div class="block w-full overflow-x-auto">
                <table class="items-center w-full bg-transparent border-collapse">
                    <thead>
                        <tr>
                            <th
                                class="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                No.</th>
                            <th
                                class="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Preview</th>
                            <th
                                class="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Judul</th>
                            <th
                                class="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Keterangan</th>
                            <th
                                class="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Operator</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(values, index) in dataApi.array" :key="index">
                            <td
                                class="px-6 py-4 border-b border-gray-200 bg-white text-sm leading-5 font-medium text-gray-900">
                                {{ index + 1 }}.</td>
                            <td class="px-6 py-4 border-b border-gray-200 bg-white text-sm leading-5 text-gray-900">
                                <img :src="getImageUrl(values.fotoservice)" alt="Service Image"
                                    class="w-24 h-24 object-cover rounded-lg" />
                            </td>
                            <td class="px-6 py-4 border-b border-gray-200 bg-white text-sm leading-5 text-gray-900">{{
                                values.judul_service }}</td>
                            <td class="px-6 py-4 border-b border-gray-200 bg-white text-sm leading-5 text-gray-900">{{
                                values.ket_service }}</td>
                            <td class="px-6 py-4 border-b border-gray-200 bg-white text-sm leading-5 text-gray-900">
                                <div class="flex flex-col sm:flex-row gap-2">
                                    <button class="bg-lightBlue-600 text-white px-3 py-1 mb-2 rounded hover:bg-blue-700"
                                        @click.prevent="editData(values)">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                    <button class="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-700"
                                        @click.prevent="confirmDelete(values.id)"
                                        :class="{ 'opacity-50 cursor-not-allowed': isloading }" :disabled="isloading">
                                        <span v-if="isloading"><i class="fas fa-spinner"></i></span>
                                        <span v-else><i class="fas fa-trash"></i></span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="dataApi.array.length === 0 && !loading">
                            <td class="px-6 py-4 border-b border-gray-200 bg-white text-sm leading-5 text-gray-500"
                                colspan="5">Tidak ada data</td>
                        </tr>
                        <tr v-if="loading">
                            <td class="px-6 py-4 border-b border-gray-200 bg-white text-sm leading-5 text-gray-500"
                                colspan="6">
                                <div class="flex justify-center items-center">
                                    <i class="fas fa-spinner fa-spin text-gray-500"></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <nav class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                    <div class="flex-1 flex sm:justify-between">
                        <button v-if="currentPage !== 1" @click="fetchData(prevPageUrl)"
                            :disabled="prevPageUrl === null"
                            class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed">Previous</button>
                        <span class="px-4 py-2 text-sm text-gray-700">{{ currentPage }} / {{ totalPages
                            }}</span>
                        <button v-if="nextPageUrl !== null" @click="fetchData(nextPageUrl)"
                            :disabled="nextPageUrl === null"
                            class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed">Next</button>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import Swal from 'sweetalert2';
import initAPI from '@/axios.js';
import Cookies from 'js-cookie';

export default {
    setup() {
        const loading = ref(false)
        const isloading = ref(false)
        const token = Cookies.get('token');
        const dataApi = ref({ array: [] });
        const currentPage = ref(1);
        let totalPages = ref(0);
        const nextPageUrl = ref('');
        const prevPageUrl = ref('');
        const tmpfile = ref(null);
        const file = ref(null);
        const showModal = ref(false);
        const formData = ref({ id: null, file: null, judul_service: '', ket_service: '' });

        const tampilFoto = (event) => {
            const fileInput = event.target.files[0];
            file.value = fileInput;
            tmpfile.value = URL.createObjectURL(fileInput);
        };

        const fetchData = async (page) => {
            try {
                loading.value = true;
                const response = await initAPI('get', `Service?page=${page}`, null, null);
                dataApi.value.array = response.data.data; response.data.data.reverse();
                currentPage.value = response.data.current_page;
                nextPageUrl.value = response.data.next_page_url ? response.data.next_page_url.split('?page=')[1] : null;
                prevPageUrl.value = response.data.prev_page_url ? response.data.prev_page_url.split('?page=')[1] : null;
                const totalData = response.data.total;
                const perPage = response.data.per_page;
                totalPages.value = Math.ceil(totalData / perPage);
            } catch (error) {
                console.error("Ada error:", error);
                showNotification('error', 'Gagal Memuat Data', 'Tidak dapat memuat data dari server.');
            } finally { loading.value = false; }
        };

        const getImageUrl = (filename) => {
            return `${process.env.VUE_APP_API_BASE_URL}images/${filename}`;
        };

        const confirmDelete = (id) => {
            Swal.fire({
                title: 'Apakah Anda yakin?',
                text: 'Anda tidak akan dapat mengembalikan ini!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya, hapus!'
            }).then((result) => {
                if (result.isConfirmed) {
                    deleteDataApi(id);
                }
            });
        };

        const deleteDataApi = async (id) => {
            try {
                isloading.value = true;
                await initAPI('delete', `Service/delete/${id}`, null, token);
                showNotification('success', 'Berhasil', 'Data berhasil dihapus.');
                fetchData('Service');
            } catch (error) {
                console.error("Error:", error.response ? error.response.data : error.message);
                showNotification('error', 'Gagal', 'Data gagal dihapus.');
            } finally { isloading.value = false; }
        };

        const editData = (values) => {
            showModal.value = true;
            formData.value = { id: values.id, file: values.fotoservice, judul_service: values.judul_service, ket_service: values.ket_service };
        };

        const saveData = async () => {

            try {
                loading.value = true;
                const formDataToSend = new FormData();
                formDataToSend.append('fotoservice', file.value);
                formDataToSend.append('judul_service', formData.value.judul_service);
                formDataToSend.append('ket_service', formData.value.ket_service);
                await initAPI('post', `Service/Update/${formData.value.id}`, formDataToSend, token);
                showNotification('success', 'Berhasil', 'Data berhasil diedit.');
                fetchData('Service');
                closeModal();
            } catch (error) {
                console.error('Error:', error.response ? error.response.data : error.message);
                showNotification('error', 'Gagal', 'Data gagal diedit.');
            } finally {
                loading.value = false;
            }
        };

        const closeModal = () => {
            showModal.value = false;
        };  

        const showNotification = (type, title, text) => {
            Swal.fire({
                icon: type,
                title: title,
                text: text,
                timer: 3000,
                showConfirmButton: false
            });
        };

        onMounted(() => {
            fetchData(currentPage.value);
        });

        return {
            loading,
            isloading,
            tampilFoto,
            dataApi,
            confirmDelete,
            editData,
            showModal,
            formData,
            saveData,
            closeModal,
            fetchData,
            getImageUrl,
            currentPage,
            nextPageUrl,
            prevPageUrl,
            totalPages
        };
    }
};
</script>