import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

// styles
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mounting point for the whole app
import App from "@/App.vue";

// layouts
// import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";

// views for Admin layout
import Dashboard from "@/views/admin/Dashboard.vue";
import Career from "@/views/admin/Career.vue";

// views for Auth layout
import Login from "@/views/auth/Login.vue";

// Tambahkan impor komponen baru di sini
import About from "@/views/admin/About.vue";
import CardSlogan from "@/components/Cards/cardsinput/about/CardSlogan.vue";
import TambahSlogan from "@/components/Cards/cardsinput/about/Tambah_slogan.vue";
import Portofolio from "@/views/admin/Portofolio.vue";
import TambahPortofolio from "@/components/Cards/cardsinput/portofolio/Tambah_Portofolio.vue";
import Hero from "@/components/Cards/cardsinput/hero/CardHero.vue";
import TambahHero from "@/components/Cards/cardsinput/hero/Tambah_Hero.vue";
import Culture from "@/components/Cards/cardsinput/culture/CardCulture.vue";
import TambahCulture from "@/components/Cards/cardsinput/culture/TambahCulture.vue";
import Value from "@/components/Cards/cardsinput/value/CardValue.vue";
import TambahValue from "@/components/Cards/cardsinput/value/TambahValue.vue";
import Team from "@/components/Cards/cardsinput/team/CardTeam.vue";
import TambahTeam from "@/components/Cards/cardsinput/team/TambahTeam.vue";
import Client from "@/components/Cards/cardsinput/client/CardClient.vue";
import TambahClient from "@/components/Cards/cardsinput/client/TambahClient.vue";
import Philosophy from "@/components/Cards/cardsinput/philosophy/CardPhilosophy.vue";
import TambahPhilosophy from "@/components/Cards/cardsinput/philosophy/TambahPhilosophy.vue";
import TambahCareer from "@/components/Cards/cardsinput/career/TambahCareer.vue";
import News from "@/components/Cards/cardsinput/news/CardNews.vue";
import TambahNews from "@/components/Cards/cardsinput/news/TambahNews.vue";
import Service from "@/components/Cards/cardsinput/service/CardService.vue";
import TambahService from "@/components/Cards/cardsinput/service/TambahService.vue";
import Brand from "@/components/Cards/cardsinput/brand/CardBrand.vue";
import TambahBrand from "@/components/Cards/cardsinput/brand/Tambah_brand.vue";
import Review from "@/components/Cards/cardsinput/review/CardReview.vue";
import TambahAlamat from "@/components/Cards/cardsinput/alamat/TambahAlamat.vue";
import Alamat from "@/components/Cards/cardsinput/alamat/CardAlamat.vue";
import TambahContact from "@/components/Cards/cardsinput/contact/TambahContact.vue";
import Contact from "@/components/Cards/cardsinput/contact/CardContact.vue";
import TambahReview from "@/components/Cards/cardsinput/review/Tambah_review.vue";
import kt_news from "@/components/Cards/cardsinput/kategori/news/CardNews.vue";
import TambahKtNews from "@/components/Cards/cardsinput/kategori/news/Tambah_kt_news.vue";
import kt_porto from "@/components/Cards/cardsinput/kategori/portofolio/CardPorto.vue";
import TambahKtPorto from "@/components/Cards/cardsinput/kategori/portofolio/Tambah_kt_porto.vue";
import CTReview from "@/components/Cards/cardsinput/kategori/review/CardReview.vue";
import TambahKtReview from "@/components/Cards/cardsinput/kategori/review/Tambah_Review.vue";


// routes
const routes = [
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    component: () => import('@/layouts/Admin.vue'),
    // component: Admin,
    children: [
      {
        path: "/admin/dashboard",
        component: Dashboard,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/about",
        component: About,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/slogan/",
        component: CardSlogan,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/slogan/input",
        component: TambahSlogan,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/hero",
        component: Hero,
        meta: { requiresAuth: true }
      }, {
        path: "/admin/hero/input",
        component: TambahHero,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/portofolio",
        component: Portofolio,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/portofolio/input",
        component: TambahPortofolio,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/culture",
        component: Culture,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/culture/input",
        component: TambahCulture,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/value",
        component: Value,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/value/input",
        component: TambahValue,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/team",
        component: Team,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/team/input",
        component: TambahTeam,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/client",
        component: Client,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/client/input",
        component: TambahClient,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/philosophy",
        component: Philosophy,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/philosophy/input",
        component: TambahPhilosophy,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/service",
        component: Service,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/service/input",
        component: TambahService,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/career/input",
        component: TambahCareer,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/career",
        component: Career,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/news",
        component: News,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/news/input",
        component: TambahNews,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/brand",
        component: Brand,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/brand/input",
        component: TambahBrand,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/review/",
        component: Review,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/review/input",
        component: TambahReview,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/contact/",
        component: Contact,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/contact/input",
        component: TambahContact,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/alamat/",
        component: Alamat,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/alamat/input",
        component: TambahAlamat,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/category_news",
        component: kt_news,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/category_news/input",
        component: TambahKtNews,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/category_porto",
        component: kt_porto,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/category_porto/input",
        component: TambahKtPorto,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/category_review",
        component: CTReview,
        meta: { requiresAuth: true }
      },
      {
        path: "/admin/category_review/input",
        component: TambahKtReview,
        meta: { requiresAuth: true }
      },
    ],
  },
  {
    path: "/auth",
    redirect: "/auth/login",
    component: Auth,
    children: [
      { path: "login", component: Login },
    ],
  },
  { path: "/", redirect: "/auth/login" },
  { path: "/:pathMatch(.*)*", redirect: "/auth/login" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const isJWT = (token) => {
  const parts = token.split('.');
  if (parts.length !== 3) {
    return false;
  }
  try {
    const payload = JSON.parse(atob(parts[1]));
    return typeof payload.exp === 'number';
  } catch (e) {
    return false;
  }
};

const checkTokenExpiry = () => {
  const token = Cookies.get('token');
  if (token && isJWT(token)) {
    try {
      const decodedToken = jwtDecode(token);
      const expiryTime = decodedToken.exp * 1000;
      const currentTime = Date.now();

      if (currentTime > expiryTime) {
        Cookies.remove('token');
        router.push('/auth/login');
      } else {
        setTimeout(checkTokenExpiry, expiryTime - currentTime);
      }
    } catch (error) {
      // Cookies.remove('token');
      router.push('/auth/login');
    }
  } else {
    Cookies.remove('token');
    router.push('/auth/login');
  }
};

let refresh = null;

window.addEventListener('beforeunload', () => {
  localStorage.setItem('aksesNow', router.currentRoute.value.fullPath);
});

router.beforeEach((to, from, next) => {
  const token = Cookies.get('token');
  const halamanSekarang = localStorage.getItem('aksesNow');

  if (refresh) clearTimeout(refresh);

  refresh = setTimeout(() => {
    if (halamanSekarang && halamanSekarang !== to.fullPath) {
      localStorage.removeItem('aksesNow');
      return next({ path: halamanSekarang });
    }

    if (to.matched.some(record => record.meta.requiresAuth) && !token) {
      return next({ path: '/auth/login', query: { redirect: to.fullPath } });
    }

    if (to.path === '/auth/login' && token) {
      return next({ path: '/admin/dashboard' });
    }

    next();
  }, 100);
});

createApp(App).use(router).mount("#app");
checkTokenExpiry();