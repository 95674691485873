<template>
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div class="rounded-t bg-white mb-0 px-6 py-6">
            <div class="text-center flex justify-between">
                <h6 class="text-blueGray-700 text-xl font-bold uppercase">Career</h6>
                <router-link to="/admin/career">
                    <button
                        class="bg-indigo-500 text-white active:bg-lightBlue-300 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button">
                        kembali ke tables
                    </button>
                </router-link>
            </div>
        </div>
        <form @submit.prevent="kirimData">
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div class="w-full lg:w-12/12 px-4 my-4">
                    <div class="w-full lg:w-12/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                                Lowongan
                            </label>
                            <input type="text" v-model="lowongan" placeholder="Masukan Lowongan"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                        </div>
                    </div>
                    <div class="w-full lg:w-12/12 px-4">
                        <div class="relative w-full my-4">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="keterangan">
                                Keterangan
                            </label>
                            <textarea id="keterangan" v-model="keterangan"
                                class="border-0 px-3 py-3 placeholder-blueGray-300  text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 justi"
                                rows="4" placeholder="Masukan keterangan disini"></textarea>
                        </div>
                    </div>
                    <div class="px-4">
                        <button type="submit" :class="{ 'opacity-50 cursor-not-allowed': isLoading }"
                            :disabled="isLoading"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-100 bg-indigo-500 rounded text-sm shadow w-full">
                            <span v-if="isLoading">Loading...</span>
                            <span v-else>KIRIM</span>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import initAPI from '@/axios.js';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

export default {
    setup() {
        const token = Cookies.get('token');
        const lowongan = ref('');
        const keterangan = ref('');
        const isLoading = ref(false);

        const postDataApi = async () => {
            if (!keterangan.value.trim() || !lowongan.value.trim()) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Kolom kosong',
                    text: 'Semua kolom harus diisi.'
                });
                return;
            }
            try {
                isLoading.value = true;
                const dataToSend = new FormData();
                dataToSend.append('lowong_krj', lowongan.value);
                dataToSend.append('ket_lowong', keterangan.value);
                const response = await initAPI('post', 'Career/Store', dataToSend, token)
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Berhasil mengirim data ke server',
                });
                lowongan.value = '';
                keterangan.value = '';
            } catch (error) {
                console.error("Error:", error);
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Gagal mengirim data ke server.',
                    timer: 3000,
                    showConfirmButton: false
                });
            } finally {
                isLoading.value = false;
            }
        };

        const kirimData = async () => {
            await postDataApi();
        };

        return { keterangan, lowongan, kirimData, isLoading };
    }
};
</script>
