<template>
  <div>
    <!-- Modal untuk Edit Portfolio -->
    <div v-if="showModal" class="relative inset-0 w-full p-32 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div class="relative w-full max-w-lg mx-auto my-6">
        <div class="bg-white rounded-lg shadow-lg overflow-hidden w-full">
          <div class="flex items-start justify-between p-5 border-b border-gray-200">
            <h3 class="text-2xl font-semibold">Edit Portfolio</h3>
            <button @click="closeModal" class="text-gray-600 hover:text-gray-900">
              <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
                <path d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
          <div class="p-6 flex-auto">
            <form @submit.prevent="saveData">
              <div class="space-y-4">
                <div class="relative my-4 mx-4" v-for="(image, index) in formData.fotoprojek" :key="index">
                  <img :src="getImageUrl(image.images)" alt="" class="rounded-lg w-[20px] h-[100px] my-3 border-2 border-black">
                  <button @click.prevent="removeImage(image.id, index)" class="absolute top-0 right-0 bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center">
                    X
                  </button>
                </div>
                <div class="my-4 mx-4">
                  <label class="block text-gray-600 text-xs font-bold mb-2">Tambah Foto</label>
                  <input type="file" @change="tampilFoto" class="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full transition-all duration-150">
                </div>
                <div class="my-4 mx-4">
                  <label class="block text-gray-600 text-xs font-bold mb-2">Edit Judul</label>
                  <input type="text" v-model="formData.judul_porto" class="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full transition-all duration-150" placeholder="Masukan judul porto">
                </div>
                <div class="my-4 mx-4">
                  <label class="block text-gray-600 text-xs font-bold mb-2">Edit Keterangan</label>
                  <textarea v-model="formData.ket_porto" class="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full transition-all duration-150" placeholder="Masukan keterangan porto"></textarea>
                </div>
                <div class="my-4 mx-4">
                  <label class="block text-black text-xs font-bold mb-2">Edit Kategori</label>
                  <select v-model="formData.Tag_ID" class="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full transition-all duration-150">
                    <option v-for="kategoriS in dataKategori" :key="kategoriS.id" :value="kategoriS.id" class="text-black">
                      {{ kategoriS.nama_category }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="flex items-center justify-end p-6 border-t border-gray-200">
                <button class="text-red-500 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none transition-all duration-150" type="button" @click="closeModal">
                  Close
                </button>
                <button :class="{ 'opacity-50 cursor-not-allowed': loading }" :disabled="loading" class="bg-indigo-500 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none transition-all duration-150" type="submit">
                  <span v-if="loading">Saving...</span>
                  <span v-else>Save Changes</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal untuk Gambar -->
    <div v-if="showImageModalStatus" class="absolute inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div class="bg-white rounded-lg shadow-lg overflow-hidden w-auto max-w-3xl">
        <div class="flex justify-end p-2">
          <button @click="closeImageModal" class="text-gray-600 hover:text-gray-900">
            <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
              <path d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
        <div class="p-4">
          <img :src="getImageUrl(currentImage.images)" class="w-full h-auto rounded-md object-cover" />
        </div>
      </div>
    </div>

    <!-- Tabel Portfolio -->
    <div class="relative bg-white flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg">
      <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center">
          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3 class="font-semibold text-lg text-blueGray-700 sm:text-sm">
              Table Portfolio
            </h3>
          </div>
          <router-link to="/admin/portofolio/input" class="flex w-full lg:w-auto">
            <span class="bg-indigo-500 shadow-md text-sm my-2 mx-4 p-3 text-white rounded w-full text-center md:w-auto md:my-4">Tambah Portfolio</span>
          </router-link>
        </div>
      </div>
      <div class="block w-full overflow-x-auto">
        <div class="table-container">
          <table class="items-center w-full bg-transparent border-collapse min-w-[600px]">
            <thead>
              <tr>
                <th class="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">No.</th>
                <th class="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Preview</th>
                <th class="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Judul</th>
                <th class="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Keterangan</th>
                <th class="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Kategori</th>
                <th class="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Operator</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="loading">
                <td class="px-6 py-4 border-b border-gray-200 bg-white text-sm leading-5 text-gray-500" colspan="6">
                  <div class="flex justify-center items-center">
                    <i class="fas fa-spinner fa-spin text-gray-500"></i>
                  </div>
                </td>
              </tr>
              <tr v-for="(values, index) in dataApi.array" :key="index">
                <td class="px-6 py-4 border-b border-gray-200 bg-white text-sm leading-5 font-medium text-gray-900">{{ index + 1 }}.</td>
                <td class="px-6 py-4 border-b border-gray-200 bg-white text-sm leading-5 text-gray-900">
                  <img :src="getImageUrl(values.images[0].images)" class="w-20 h-20 rounded-md object-cover cursor-pointer" @click="showImageModal(index)" />
                </td>
                <td class="px-6 py-4 border-b border-gray-200 bg-white text-sm leading-5 text-gray-900">
                  {{ truncateText(values.judul_porto) }}
                  <button v-if="values.judul_porto.length > 100" @click="showDetails(values.judul_porto, values.ket_porto)">...</button>
                </td>
                <td class="px-6 py-4 border-b border-gray-200 bg-white text-sm leading-5 text-gray-900">
                  {{ truncateText(values.ket_porto) }}
                  <button v-if="values.ket_porto.length > 100" @click="showDetails(values.judul_porto, values.ket_porto)" class="text-lightBlue-600">...</button>
                </td>
                <td class="px-6 py-4 border-b border-gray-200 bg-white text-sm leading-5 text-gray-900">{{ values.Kategori }}</td>
                <td class="px-6 py-4 border-b border-gray-200 bg-white text-sm leading-5 text-gray-900">
                  <div class="flex flex-col sm:flex-row gap-2">
                    <button class="bg-lightBlue-600 text-white px-3 py-1 mb-2 rounded hover:bg-blue-700" @click.prevent="EditData(values)">
                      <i class="fas fa-edit"></i>
                    </button>
                    <button class="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-700" @click.prevent="confirmDelete(values.id)" :class="{ 'opacity-50 cursor-not-allowed': isloading }" :disabled="isloading">
                      <span v-if="isloading"><i class="fas fa-spinner"></i></span>
                      <span v-else><i class="fas fa-trash"></i></span>
                    </button>
                  </div>
                </td>
              </tr>
              <tr v-if="dataApi.array.length === 0 && !loading">
                <td class="px-6 py-4 border-b border-gray-200 bg-white text-sm leading-5 text-gray-500" colspan="6">Tidak ada data</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <nav class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div class="flex-1 flex sm:justify-between">
          <button v-if="currentPage !== 1" @click="fetchData(prevPageUrl)" :disabled="prevPageUrl === null" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed">
            Previous
          </button>
          <span class="px-4 py-2 text-sm text-gray-700">{{ currentPage }} / {{ totalPages }}</span>
          <button v-if="nextPageUrl !== null" @click="fetchData(nextPageUrl)" :disabled="nextPageUrl === null" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed">
            Next
          </button>
        </div>
      </nav>
    </div>

    <!-- Modal untuk Detail -->
    <div v-if="showDetailsModal" class="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div class="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
        <div class="flex justify-between items-center">
          <h2 class="text-2xl font-semibold">Detail Portfolio</h2>
          <button @click="closeDetailsModal" class="text-2xl">&times;</button>
        </div>
        <hr class="my-4" />
        <h3 class="text-xl font-semibold">{{ detailsTitle }}</h3>
        <p class="mt-4">{{ detailsText }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import Swal from "sweetalert2";
import initAPI from "@/axios.js";
import Cookies from "js-cookie";

export default {
  setup() {
    const loading = ref(false);
    const isloading = ref(false);
    const token = Cookies.get("token");
    const dataKategori = ref([]);
    const dataApi = ref({ array: [] });
    const currentPage = ref(1);
    const totalPages = ref(0);
    const nextPageUrl = ref("");
    const prevPageUrl = ref("");
    const tmpfile = ref(null);
    const file = ref(null);
    const showModal = ref(false);
    const showDetailsModal = ref(false);
    const detailsText = ref("");
    const detailsTitle = ref("");
    const showImageModalStatus = ref(false); // Ubah nama variabel ini
    const currentImage = ref({ images: "" });
    const formData = ref({
      id: null,
      fotoprojek: [],
      Tag_ID: "",
      ket_porto: "",
      judul_porto: "",
    });

    const tampilFoto = (event) => {
      const fileInput = event.target.files[0];
      file.value = fileInput;
      tmpfile.value = URL.createObjectURL(fileInput);
    };

    const fetchData = async (page) => {
      try {
        loading.value = true;
        const response = await initAPI("get", `Portofolio?page=${page}`, null, null);
        dataApi.value.array = response.data.data.reverse();
        currentPage.value = response.data.current_page;
        nextPageUrl.value = response.data.next_page_url ? response.data.next_page_url.split("?page=")[1] : null;
        prevPageUrl.value = response.data.prev_page_url ? response.data.prev_page_url.split("?page=")[1] : null;
        const totalData = response.data.total;
        const perPage = response.data.per_page;
        totalPages.value = Math.ceil(totalData / perPage);
      } catch (error) {
        console.error("Error fetching data:", error);
        showNotificationMessage("error", "Gagal Memuat Data", "Tidak dapat memuat data dari server.");
      } finally {
        loading.value = false;
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await initAPI("get", "Category", null, null);
        dataKategori.value = response.data.data;
      } catch (error) {
        console.error("Ada error:", error);
      }
    };

    const truncateText = (text, length = 30) => {
      return text.length > length ? text.slice(0, length) + "..." : text;
    };

    const getImageUrl = (filename) => {
      return `${process.env.VUE_APP_API_BASE_URL}images/${filename}`;
    };

    const removeImage = async (imageId, index) => {
      try {
        await initAPI("delete", `fotoprojek/delete/${imageId}`, null, token);
        formData.value.fotoprojek.splice(index, 1);
        showNotificationMessage("success", "Berhasil", "Gambar berhasil dihapus.");
      } catch (error) {
        console.error("Error removing image:", error);
        showNotificationMessage("error", "Gagal", "Gambar gagal dihapus.");
      }
    };

    const EditData = (values) => {
      showModal.value = true;
      formData.value = {
        id: values.id,
        fotoprojek: values.images.map((img) => ({ id: img.id, images: img.images })),
        ket_porto: values.ket_porto,
        judul_porto: values.judul_porto,
        Tag_ID: values.Tag_ID,
      };
      tmpfile.value = null;
    };

    const saveData = async () => {
      try {
        loading.value = true;
        if (file.value) {
          const formDataImage = new FormData();
          formDataImage.append("images", file.value);
          formDataImage.append("project_id", formData.value.id);
          await initAPI("post", `fotoprojek/Store`, formDataImage, token);
          file.value = null;
          tmpfile.value = null;
        }

        const formDataToSend = new FormData();
        formDataToSend.append("Tag_ID", formData.value.Tag_ID);
        formDataToSend.append("ket_porto", formData.value.ket_porto);
        formDataToSend.append("judul_porto", formData.value.judul_porto);

        const updateResponse = await initAPI("post", `Portofolio/Update/${formData.value.id}`, formDataToSend, token);
        if (updateResponse.status !== 200) {
          throw new Error("Failed to save data");
        }

        showNotificationMessage("success", "Berhasil", "Data berhasil disimpan.");
        fetchData(currentPage.value);
        closeModal();
      } catch (error) {
        console.error("Error saving data:", error.response ? error.response.data : error.message);
        showNotificationMessage("error", "Gagal", "Data gagal disimpan.");
      } finally {
        loading.value = false;
      }
    };

    const closeModal = () => {
      showModal.value = false;
    };

    const closeDetailsModal = () => {
      showDetailsModal.value = false;
    };

    const showNotificationMessage = (type, message, text) => {
      Swal.fire({
        icon: type,
        title: message,
        text: text,
        timer: 3000,
        showConfirmButton: false,
      });
    };

    const confirmDelete = (id) => {
      Swal.fire({
        title: "Apakah Anda yakin?",
        text: "Anda tidak akan dapat mengembalikan ini!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteDataApi(id);
        }
      });
    };

    const deleteDataApi = async (id) => {
      try {
        isloading.value = true;
        await initAPI("delete", `Portofolio/delete/${id}`, null, token);
        showNotificationMessage("success", "Berhasil", "Data berhasil dihapus.");
        fetchData(currentPage.value);
      } catch (error) {
        console.error("Error:", error.response ? error.response.data : error.message);
        showNotificationMessage("error", "Gagal", "Data gagal dihapus.");
      } finally {
        isloading.value = false;
      }
    };

    const showImageModal = (index) => {
      currentImage.value = dataApi.value.array[index].images[0];
      showImageModalStatus.value = true;
    };

    const closeImageModal = () => {
      showImageModalStatus.value = false;
    };

    onMounted(() => {
      fetchData(currentPage.value);
      fetchCategories();
    });

    return {
      tampilFoto,
      dataKategori,
      showModal,
      showImageModalStatus, // Perbarui di sini
      closeImageModal,
      formData,
      saveData,
      closeModal,
      getImageUrl,
      loading,
      removeImage,
      showDetailsModal,
      detailsText,
      detailsTitle,
      isloading,
      currentPage,
      nextPageUrl,
      prevPageUrl,
      fetchData,
      totalPages,
      closeDetailsModal,
      dataApi,
      truncateText,
      EditData,
      confirmDelete,
      showImageModal, // Tambahkan di sini
    };
  },
};
</script>
