<template>
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div class="rounded-t bg-white mb-0 px-6 py-6">
            <div class="text-center flex justify-between">
                <h6 class="text-blueGray-700 text-xl font-bold uppercase">Team</h6>
                <router-link to="/admin/team">
                    <button
                        class="bg-indigo-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md focus:outline-none ease-linear transition-all duration-150">
                        kembali ke tables
                    </button>
                </router-link>
            </div>
        </div>
        <form @submit.prevent="kirimData">
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                <img :src="data.tmpfile" v-if="data.tmpfile"
                    class="rounded-lg w-[1160px] h-[760px] my-3 border-2 border-black" />
                <img src="https://fakeimg.pl/800x400/" v-else alt="Gambar Cadangan"
                    class="rounded-lg w-full h-[760px] my-3 border-2 border-black" />
                <div class="w-full lg:w-12/12 px-4 my-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Upload Foto</label>
                        <input type="file" @change="tampilFoto" accept="image/*"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                    </div>
                </div>
                <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Nama</label>
                        <input type="text" v-model="data.nama"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Masukan Nama" />
                    </div>
                </div>
                <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Jabatan</label>
                        <input type="text" v-model="data.jabatan"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Masukan Jabatan" />
                    </div>
                </div>
                <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Instagram</label>
                        <input type="text" v-model="data.ig"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Masukan link instagram" />
                    </div>
                </div>
                <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">LinkedIn</label>
                        <input type="text" v-model="data.lin"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Masukan link LinkedIn" />
                    </div>
                </div>
                <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Twitter</label>
                        <input type="text" v-model="data.twt"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Masukan link Twitter" />
                    </div>
                </div>
                <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Facebook</label>
                        <input type="text" v-model="data.fb"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Masukan link Facebook" />
                    </div>
                </div>
                <div class="px-4">
                    <button type="submit" :class="{ 'opacity-50 cursor-not-allowed': isLoading }" :disabled="isLoading"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-100 bg-indigo-500 rounded text-sm shadow w-full">
                        <span v-if="isLoading">Loading...</span>
                        <span v-else>KIRIM</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
/*eslint-disable*/
import { reactive, ref } from 'vue';
import initApi from '@/axios.js';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
export default {
    setup() {
        const token = Cookies.get('token')
        const data = reactive({
            nama: '',
            jabatan: '',
            ig: 'https://www.instagram.com/',
            fb: 'https://www.facebook.com/',
            twt: 'https://x.com/',
            lin: 'https://www.linkedin.com/',
            file: null,
            tmpfile: null,
        });

        const isLoading = ref(false);

        const tampilFoto = (event) => {
            const fileInput = event.target.files[0];
            data.file = fileInput;
            data.tmpfile = URL.createObjectURL(fileInput);
        };

        const postDataApi = async () => {
            if (
                !data.nama.trim() ||
                !data.jabatan.trim() ||
                !data.file
            ) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Kolom kosong',
                    text: 'Semua kolom harus diisi.'
                });
                return;
            }

            isLoading.value = true;
            try {
                const dataToSend = new FormData();
                dataToSend.append('nama_orang', data.nama);
                dataToSend.append('jabatan', data.jabatan);
                dataToSend.append('link_ig', data.ig);
                dataToSend.append('link_twt', data.twt);
                dataToSend.append('link_in', data.lin);
                dataToSend.append('link_fb', data.fb);
                dataToSend.append('foto_orang', data.file);
                dataToSend.append('created_at', new Date().toISOString());
                const response = await initApi('post', `abt_team/Store`, dataToSend, token);
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Berhasil mengirim data ke server',
                });
                data.nama = '';
                data.jabatan = '';
                data.ig = 'https://www.instagram.com/';
                data.fb = 'https://www.facebook.com/';
                data.twt = 'https://x.com/';
                data.lin = 'https://www.linkedin.com/';
                data.file = null;
                data.tmpfile = null;
            } catch (error) {
                console.error('Error:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Gagal mengirim data ke server.',
                    timer: 3000,
                    showConfirmButton: false
                });
            } finally {
                isLoading.value = false;
            }
        };

        const kirimData = async () => {
            await postDataApi();
        };

        return { data, kirimData, tampilFoto, isLoading };
    },
};
</script>
